import React, { Fragment } from "react"
import { Link } from "gatsby"

const AuthLinks = ({ module }) => {
  const isSignIn = module.name === "sign-in"
  const isForgotPassword = module.name === "forgot-password"

  return (
    <Fragment>
      {isSignIn && (
        <section>
          <p className="has-text-centered is-size-6 mt-2">
            Don't have an account yet?{" "}
            <Link to="/verify-email">Enroll Now.</Link>
          </p>
        </section>
      )}
      {!isSignIn && !isForgotPassword && (
        <section>
          <p className="has-text-centered is-size-7 pt-1">
            By signing up, you agree to our{" "}
            <Link to="/terms-and-conditions">Terms</Link> and{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p className="has-text-centered is-size-6">
            Already have an account? <Link to="/sign-in">Sign In.</Link>
          </p>
        </section>
      )}
      {isForgotPassword && (
        <section>
          <p className="has-text-centered is-size-6">
            Already have an account? <Link to="/sign-in">Sign In.</Link>
          </p>
        </section>
      )}
    </Fragment>
  )
}

export default AuthLinks
