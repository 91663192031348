export const authFormFields = (values) => {
  return [
    {
      type: "text",
      name: "email",
      label: "Work Email",
      isRequired: true,
      value: values.email,
      isFollowUpQuestion: false,
      disabled: values.hasBeenVerified,
      placeholder: "jdelacruz@taskus.com",
      inclusions: ["sign-in", "verify-email", "sign-up", "forgot-password"],
    },
    {
      type: "password",
      name: "password",
      isRequired: true,
      label: "Password",
      placeholder: "********",
      isFollowUpQuestion: false,
      inclusions: ["sign-in", "sign-up"],
    },
    {
      type: "password",
      name: "confirmPassword",
      isRequired: true,
      label: "Confirm Password",
      placeholder: "********",
      isFollowUpQuestion: false,
      inclusions: ["sign-up"],
    },
  ]
}
